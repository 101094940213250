import { Navigate } from "react-router-dom";
import withAuthentication from "./WithAuth";
import PermanentDrawerLeft from "../Pages/PermanentDrawerLeft";

//@ts-ignore
const PrivateRoute = ({ children, isValid, ...rest }) => {
  return isValid ? <PermanentDrawerLeft /> : <Navigate to="/login" />;
  // return <PermanentDrawerLeft />;
};

export default withAuthentication(PrivateRoute);
