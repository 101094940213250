import Amplify from "aws-amplify";
import { BrowserRouter as Router } from "react-router-dom";
import config from "./Configs/AwsExports";
import Routes from "./Routing/Routes";


Amplify.configure(config);

const App = () => {
  return (
    <>
      <Router>
        <Routes />
      </Router>
    </>
  );
};

export default App;
