import { CognitoUserPool } from "amazon-cognito-identity-js";

const userPoolId = process.env.REACT_APP_USER_POOL_ID;
const clientId = process.env.REACT_APP_CLIENT_ID;

const poolData: any = {
  UserPoolId: userPoolId,
  ClientId: clientId,
};

export default new CognitoUserPool(poolData);
