const AwsExports = {
  aws_project_region: process.env.REACT_APP_PROJECT_REGION,
  aws_cognito_identity_pool_id: process.env.REACT_APP_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_CLIENT_ID,
  oauth: {
    domain: process.env.REACT_APP_USER_POOL_DOMAIN,
    scope: ["email", "openid", "profile"],
    redirectSignIn: `${window.location.protocol}//${window.location.host}/home`,
    redirectSignOut: `${window.location.protocol}//${window.location.host}/`,
    responseType: "code",
  },

  federationTarget: "COGNITO_USER_POOLS",
};

export default AwsExports;
