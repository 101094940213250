import React, { useEffect, useState } from "react";
import { Grid, Card, CardMedia, Typography} from '@mui/material';
import { Link } from "react-router-dom";

function Initiative(): JSX.Element {

    // con sole the domain name
    //console.log(window.location.hostname);

    type Group = string; // Replace "any" with your actual group type

    const [groups, setGroups] = useState<Group[]>([]);

    const fetchGroups = async () => {
        const cognitoGroups = localStorage.getItem("cognitoGroups");
        console.log("cognitoGroups");
        console.log(cognitoGroups);
    
        if (cognitoGroups) {
            setGroups(cognitoGroups.split(','));
            //setGroups(JSON.parse(cognitoGroups));
        

        } else {
          setGroups([]);
        }

        console.log("groups");
        console.log(groups);
      };
    
      useEffect(() => {
        fetchGroups();
      }, []);


    // const [portalUsers, setPortalUsers] = useState([]);

    // const fetchPortalUsers = async () => {
    //   const response = await fetch("http://localhost:8000/not_secure")
    //   const portalUsers = await response.json()
    //   console.log(portalUsers)
    //   setPortalUsers(portalUsers)
    // }

    // useEffect(() => {
    //     fetchPortalUsers()
    // }, [])

    return (
        <div>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={5}
            >                
                {/* <p>
                {portalUsers}
                </p> */}

                <Grid item xs={12} md={8} lg={12}>
                    <Typography variant="h2" component="h2" color={'#000000'} marginBottom={'20px'}>
                    <b>Choose</b> Initiative</Typography>

                    {/* <Grid container 
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                    sx={{ marginBottom: "20px" }}>

                        <Grid item md={4}>
                            <img width={170} height={190} src={"/Data.png"} />
                        </Grid>
                
                        <Grid item md={4}>
                        <img width={170} height={190} src={"/Analysis.png"} />
                        </Grid>

                        <Grid item md={4}>
                        <img width={170} height={190} src={"/Visualisations.png"} />
                        </Grid>

                    </Grid> */}

                </Grid>

                <Grid item lg={8}>


{/* 
<p>

<h1>Overview</h1>

This application serves to analyse and monitor growth of companies that underwent an ethical screening of all the active businesses on the Clout/SA portal.

<hr />

<h1>Step 1 – Ethical Business Assessment </h1>

<h3>Objectives</h3>

The core objectives for conducting an ethical assessment of Clout SA’s portal supplier database are:

<ul>
<li>Legal and Values alignment - to gain a better understanding of how its portfolio of suppliers are adhering to South African legislation and align with Clout SA’s value system.</li>
<li>Risk Mitigation –clarify the potential legal risks the portal may confront by expanding the market access for the businesses involved.  </li>
<li>Development Baseline – create a baseline, specifically related to legal compliance and Clout’s SA values for all the businesses active on the portal.  This baseline can function as a tool for creating specific areas of improvement for each business as they mature, grow and expand their customer base.  </li>
</ul>

<h3>Process</h3>

The process for establishing a legal and ethical baseline with Clout SA’s existing suppliers will involve collecting data from both the suppliers and their employees.  The suppliers will be sent a self-assessment questionnaire, while the staff will be interviewed by an Impact Amplifier team member.  <br /><br />

The combination of these two data sets, will be scored using a tool that flags Clout SA’s risks, but also areas which, Clout SA’s may want its suppliers to improve on, based on its values system.   This  information will allow Clout SA to establish growth expectation, monitor progress and determine how it has influenced improvements regarding a range of core metrics, like Health and Safety or a Living Wage for example.     <br /><br />

Each business will be assessed regarding the following 12 areas.   <br /><br />

<b>CATEGORY</b><br/>

<ul>
<li> Legal and General: addresses issues of registrations, licences, insurance
</li><li> Labour Standards: contracted terms, wage levels, hours, overtime, freedom of association
</li><li> Human Resource Practice: policies, general relations between management and staff, performance management, harassment, incentives
</li><li> Skills Development: plan in place, training offered, fair allocation of what is offered, annual spend
</li><li> Employment Equity: plan in place, wage and responsibility equity across race and gender 
</li><li> Ownership and Control: based on race, gender, staff
</li><li> Procurement: preferential policies, local economic development, small business
</li><li> Social/Community Investment - annual spend, issues of focus, alternative  forms of community investment
</li><li> Environmental & Conservation: environmental impact assessment completed, conservation plans in place, staff involvement
</li><li> Health and Safety: legal compliance, management commitment, employee  risks
</li><li> Workplace Culture: communications, staff role in decision making, discrimination policy, codes of conduct
</li><li> Personal Well Being: HIV policies and approach, wellness education, programmatic support
</li>
</ul>


These 12 focus areas will be scored across two dimensions: 
<ul>
<li> Core Compliance – this is principally related to the business’ legal compliance, but it is also related to basic responsible business practices; and 
</li><li> Values Alignment – reviews how closely the business aligns to what Clout SA has self-defined as important and the standards of business practices it aspires to. 
</li>
</ul>

To finalise the scoring tool, it will require further refinement by the Clout SA team.  This relates to two distinct issues:

<ul>
<li> Business Size - some of the issues above are not appropriate or should be considered differently for companies with less than five employees.  We recommend that the scoring is adapted and different for companies with employees below and above five people.   
</li><li> Values Alignment - 30-40% of the overall scores will be based on how Clout SA has defined what is most important to it as a business.  Deciding what matters to Clout SA will then affect how the values alignment section of the scoring is weighted.
Based on the Portal’s active supplier base of 40 businesses and the number of employees in total it is expected that all 40 owners will complete the self-assessment and a further 160 employees will be interviewed.  As the companies are generally small, a traditional sample size for employees to interview cannot be used.  Instead of interviewing all employees we recommend using a large percentage (60%+ on average) be interviewed that are representative (age, gender, race, salary, function or any other factor that may be relevant) of the entire employees per company. 
</li>
</ul>

<hr />

<h1>Step 2 – Household Assessment</h1>

<h3>Objective</h3>

An underlying assumption is that one of Clout SA’s developmental intentions include the health and well-being of the Portal business employees.  Based on this assumption, the core objective for conducting household assessments of the Portal supplier employees are:

<ul>
<li> Baseline – prior to fully developing Clout SA business development support, market access, working capital and other programmes it is important to understand how the employees of the Portal’s suppliers are addressing their basic human needs such as housing, food security, health care and education.   Understanding these issues at the household level will provide Clout SA with the information it needs to establish impact objectives for its programme interventions regarding improving the living conditions of the those potentially most affected by its support.  
</li>
</ul>

<h3>Process</h3>

To measure future impact at the household level, the focus will be on existing employees of the companies currently active on the Portal.   A representative sample of employees from all the businesses will be interviewed to gain information about the following broad categories of their lives: 
<ul>
<li> Housing;
</li><li> Education;
</li><li> Energy;
</li><li> Food Security;
</li><li> Water and Effluent; and
</li><li> Healthcare.
</li>
</ul>
This information will then be aggregated at the company and company type level (designer makers, licensed designers, and pattern designers) as a baseline from which, Clout SA can then measure any impact that may occur because of its support programmes.  

<hr />

<h1>Step 3 – Online Dashboards and Monitoring System</h1>

<h3>Objectives</h3>

The core objectives for the online dashboards and monitoring system are: 

<ul>
<li> Analysis – build an online tool to review and visualize the data collected during all three steps above, which allows Clout SA to understand the businesses on the Portal individually but, also as a group to determine the best ways of supporting them to thrive.
</li><li> Monitoring – the tools designed should allow Clout SA to first establish a baseline across the various metrics defined above and then monitor progress related to these issues based on Clout SA’s interventions and or organic growth of the businesses.  
This platform should allow Clout SA a key tool for understanding the businesses on the platform to make strategic decisions, support interventions, monitor progress, adapt and implement again. 
</li>
</ul>

<h3>Process</h3>

The process will include determining, with Clout SA, how it wants the data to be structured, what filtering options it requires, what the headline dashboards will be and what other additional information it may want to track on the system developed.  Once clarified, a brief will be developed to guide the platform developers.  Once the platform is built it will go through at least two feedback sessions to make whatever adaptations are required.


</p> */}
{/* 
<p>

Welcome to the CloutSA ethical supplier portal.   This portal’s core objectives are:  <br /><br />

<ul>
<li><b>Legal and Values alignment: </b> to gain a better understanding of how its portfolio of suppliers are adhering to South African legislation and align with CloutSA’s value system.
</li><li><b>Risk Mitigation: </b> clarify the potential legal risks the CloutSA may confront by expanding the market access for the businesses involved.  
</li><li><b>Development Baseline: </b> establish a baseline, specifically related to legal compliance and CloutsSA’s values for all the businesses active on the portal.  This baseline functions as a tool for creating specific areas of improvement for each business as they mature, grow and expand their customer base.  <br /><br/>

The process, scoring system, and business analysis reflected on this portal was conducted by Impact Amplifier, an independent consultancy with domain expertise in developing ethical supply chains (<a href="https://www.impactamplifier.co.za/" target="_blank">www.impactamplifier.co.za</a>). 
</li>
</ul>

<h1>Process</h1>

The process for establishing a legal and ethical baseline with CloutSA’s existing suppliers involved collecting data from both the suppliers and their employees.  The suppliers were sent a self-assessment questionnaire, while the staff were interviewed directly.  <br /><br />

The combination of these two data sets, was scored using a tool that flags CloutSA’s risks, but also areas which, CloutSA’s wants its suppliers to improve on, based on its values system.   This information allows CloutSA to establish growth expectation, monitor progress and determine how it has influenced improvements regarding a range of core metrics, like Health and Safety or a Living Wage for example.   
Each business was assessed regarding the following 12 areas.   <br /><br />

<h3>Category</h3>
<ul>
<li><b>
Legal and General: </b> addresses issues of registrations, licences, insurance
</li><li><b>Labour Standards: </b> contracted terms, wage levels, hours, overtime, freedom of association
</li><li><b>Human Resource Practice: </b> policies, general relations between management and staff, performance management, harassment, incentives
</li><li><b>Skills Development: </b> plan in place, training offered, fair allocation of what is offered, annual spend
</li><li><b>Employment Equity: </b> plan in place, wage and responsibility equity across race and gender 
</li><li><b>Ownership and Control: </b> based on race, gender, staff
</li><li><b>Procurement: </b> preferential policies, local economic development, small business
</li><li><b>Social/Community Investment: </b> annual spend, issues of focus, alternative forms of community investment
</li><li><b>Environmental & Conservation: </b> environmental impact assessment completed, conservation plans in place, staff involvement
</li><li><b>Health and Safety: </b> legal compliance, management commitment, employee risks
</li><li><b>Workplace Culture: </b> communications, staff role in decision making, discrimination policy, codes of conduct
</li><li><b>Personal Well Being: </b> HIV policies and approach, wellness education, programmatic support
</li>
</ul>

These 12 focus areas were scored across two dimensions: 
<ul>
<li><b>Core Compliance: </b> this is principally related to the business’ legal compliance, but it is also related to basic responsible business practices; and 
</li><li><b>Values Alignment: </b> reviews how closely the business aligns to what Clout SA has self-defined as important and the standards of business practices it aspires to. 
</li>
</ul>

To finalise the scoring tool, required further refinement by the CloutSA team.  This relates to two distinct issues:

<ul>
<li><b>Business Size: </b> some of the issues above are not appropriate or should be considered differently for companies with less than six employees.  The scoring tools were therefore adapted for three separate sizes: sole proprietors; businesses with 1-5 employees; and businesses with six or more employees.  
</li><li><b>Values Alignment: </b> 30-40% of the overall scores are based on how CloutSA has defined what is most important to it as a business.  While the categories being scored was not affected, these decisions did affect how different aspects of the values section of the scoring was weighted.
</li>
</ul>

<h3>Site Navigation</h3>

This site can be navigated by comparing all the businesses on the site, reviewing them by size, locations, type and individually.   The navigation page explains further various ways to review and consider the data on this site.   

</p> */}


Please choose from the following initiatives: <br /><br />



{groups && groups.map((group) => (
    <>

        <a href={"https://" + group.toLowerCase() + "." + window.location.hostname.replace("dapp.","").replace("app.","") + "/"} >{group}</a><br />

    </>
))}




</Grid>

<Grid item lg={4}>

 {/* show the mag1.jpeg image in the browser, make the width 200px */}
 <img width={300} src={"/chart.jpg"} /> <br /><br />



</Grid>



                {/* <Grid item xs={12} md={6} lg={5}>
                
                    <Grid container 
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={5}>

                        <Grid item xs={12}>
                        <Typography variant="h4" component="h4" color={'#000000'} ></Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <img width={250} src={"/nandos_logo.svg"} />
                        </Grid>

                        <Grid item xs={12}>
                        <Card sx={{ maxWidth: 500 }}>
                            <CardMedia
                                component="img"
                                height="200"
                                image="/im1.jpg"
                            />
                            </Card>
                        </Grid>

                        <Grid item xs={12}>
                        <Card sx={{ maxWidth: 500 }}>
                            <CardMedia
                                component="img"
                                height="200"
                                image="/im2.png"
                            />
                            </Card>
                        </Grid>

                        <Grid item xs={12}>
                        <Card sx={{ maxWidth: 500 }}>
                            <CardMedia
                                component="img"
                                height="200"
                                image="/im4.jpg"
                            />
                            </Card>
                        </Grid>

                    </Grid>
                </Grid> */}
            </Grid>
        </div>
    );
  }
  
  export default Initiative;