import { Auth } from "aws-amplify";
import React, { useEffect } from "react";

const withAuth = (Component: any) => (props: any) => {
  const [valid, setValid] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);

  useEffect(() => {
    getAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAuth = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      setValid(true);
    } catch (error) {
      setValid(false);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return null;

  return <Component {...props} isValid={valid} />;
};
export default withAuth;
