import { Route, Routes, Navigate } from "react-router-dom";
import Login from "../Pages/Login";
import Description from "../Pages/Description";
import Detail from "../Pages/Detail";
import Project from "../Pages/Project";
import Dashboard from "../Pages/Dashboard";
import Companies from "../Pages/Companies";
import PrivateRoutes from "../Routing/PrivateRoutes";
import PermanentDrawerLeft from "../Pages/PermanentDrawerLeft";
import Country from "../Pages/Country";
import CompanyDetails from "../Pages/CompanyDetails";
import { Company } from "../Models/Company";
import Categories from "../Pages/Categories";
import Comparison from "../Pages/Comparison";
import Initiative from "../Pages/Initiative";


const Routing = () => {




  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/initiative" element={<Initiative />} />

          {/* <Route path="/project" element={<Project />} />
          <Route path="/description" element={<Description />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/companies" element={<Companies />} />
          <Route path="/detail/:img" element={<Detail />} />
          <Route path="/country" element={<Country />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/companyDetails" element={<CompanyDetails />} />
          <Route path="/comparison" element={<Comparison />} /> */}

        </Route>
        <Route path="*" element={<Navigate to="/initiative" replace />} />
      </Routes>
    </>
  );
};

export default Routing;
