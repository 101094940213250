import {Box, Drawer, CssBaseline, List, Typography, Divider, ListItem, ListItemButton, ListItemIcon, ListItemText, Hidden, Grid} from '@mui/material';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import DisplaySettingsOutlinedIcon from '@mui/icons-material/DisplaySettingsOutlined';
import CompareOutlinedIcon from '@mui/icons-material/CompareOutlined';
import { Outlet } from "react-router-dom";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useNavigate } from "react-router";
import { Auth } from "aws-amplify";

const drawerWidth = 240;

export default function PermanentDrawerLeft() {

    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const signOut = async () => {
        try {
          setLoading(true);
          await Auth.signOut();
          setLoading(false);
          navigate("/login");
        } catch (error) {
          alert(error);
        }
      };

  return (
    <>
      {loading && (
        <div style={{ width: "100%", textAlign: "center", position: "absolute", backgroundColor: "grey", height: "100vh", zIndex: "9999", opacity: "0.4", }}>
          <div className="spinner-border float-right" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      )}
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        {/*<AppBar
            position="fixed"
            sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
          >
        */}
      
        <Drawer
        PaperProps={{
          sx: {
          backgroundColor: "#333333",
          color: "#ffffff",
        }}}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="permanent"
        >
                      
          <Grid padding={'10px'} container direction="row" justifyContent="flex-start" alignItems="center">
            <img width={35} src={"/viewer_portal_logo.png"} /> 
            <Typography variant="h5" color={'#ffffff'} marginLeft={'25px'}>Viewer Portal</Typography>
          </Grid>
           
          <Divider sx={{ bgcolor: "#ffffff" }}/>
          <List>

          { /*}
            {['Project', 'Description', 'Dashboard'].map((text, index) => (
              <ListItem component={Link} to={text} key={text} disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            ))}
           */}

                       
            <ListItem sx={{ color: "white" }} component={Link} to="initiative" disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <FolderOutlinedIcon sx={{ color: "white" }}/>
                </ListItemIcon>
                <ListItemText sx={{ color: "white" }} secondaryTypographyProps={{ sx: { color: "green" } }}primary="Initiative" />
              </ListItemButton>
            </ListItem>
{/* 
            
            <ListItem sx={{ color: "white" }} component={Link} to="project" disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <FolderOutlinedIcon sx={{ color: "white" }}/>
                </ListItemIcon>
                <ListItemText sx={{ color: "white" }} secondaryTypographyProps={{ sx: { color: "green" } }}primary="Clout/SA" />
              </ListItemButton>
            </ListItem>

            <ListItem component={Link} to="description" disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <DescriptionOutlinedIcon sx={{ color: "white" }}/>
                </ListItemIcon>
                <ListItemText sx={{ color: "white" }} primary="Navigation" />
              </ListItemButton>
            </ListItem>

            <ListItem component={Link} to="dashboard" disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <DashboardOutlinedIcon sx={{ color: "white" }}/>
                </ListItemIcon>
                <ListItemText sx={{ color: "white" }} primary="Dashboards" />
              </ListItemButton>
            </ListItem> */}

            {/* <ListItem component={Link} to="companies" disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <BusinessOutlinedIcon sx={{ color: "white" }}/>
                </ListItemIcon>
                <ListItemText sx={{ color: "white" }} primary="Companies" />
              </ListItemButton>
            </ListItem> */}
{/* 
            <ListItem component={Link} to="companyDetails" disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <DisplaySettingsOutlinedIcon sx={{ color: "white" }}/>
                </ListItemIcon>
                <ListItemText sx={{ color: "white" }} primary="Company Details" />
              </ListItemButton>
            </ListItem>

            <ListItem component={Link} to="categories" disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <CategoryOutlinedIcon sx={{ color: "white" }}/>
                </ListItemIcon>
                <ListItemText sx={{ color: "white" }} primary="Categories" />
              </ListItemButton>
            </ListItem>


            <ListItem component={Link} to="comparison" disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <CompareOutlinedIcon sx={{ color: "white" }}/>
                </ListItemIcon>
                <ListItemText sx={{ color: "white" }} primary="Comparison" />
              </ListItemButton>
            </ListItem> */}

            {/* <ListItem component={Link} to="country" disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <PublicOutlinedIcon sx={{ color: "white" }}/>
                </ListItemIcon>
                <ListItemText sx={{ color: "white" }} primary="Country Activities" />
              </ListItemButton>
            </ListItem> */}

          { /*
            <ListItem component={Link} to="basket" disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <DashboardOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Basket" />
              </ListItemButton>
            </ListItem>
            <ListItem component={Link} to="bucket" disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <DashboardOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Bucket" />
              </ListItemButton>
            </ListItem>
            <ListItem component={Link} to="rules" disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <DashboardOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Rules" />
              </ListItemButton>
            </ListItem>
            */}

          </List>

          <Divider sx={{ bgcolor: "#ffffff" }} />

          <List>
            {['Logout'].map((text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton onClick={() => signOut()}>
                  <ListItemIcon>
                    <LogoutOutlinedIcon sx={{ color: "white" }}/>
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>

        </Drawer>
        
        <Box
          component="main"
          sx={{ flexGrow: 1, minHeight:'100vh', bgcolor: '#F4F4F4', paddingTop: 2, paddingLeft: 7, paddingRight: 7}}
        >                      
        <Outlet />
        </Box>

      </Box>
    </>
  );
}
